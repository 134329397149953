import { snakeCase } from 'lodash'
import { Pagination } from './generated/graphql'
import { diff, stripTypename } from './utils'
import { QueryBuilder, QueryBuilderUnit } from './queries'

interface Sort {
  field: string
  direction: string
}

function defaultOrderByMapper(sort: Sort) {
  return {
    field: snakeCase(sort.field).toUpperCase(),
    direction: sort.direction,
  }
}

function defaultParseResponseGetList(data: any) {
  return data.data
}

function defaultParseResponseGetOne(data: any) {
  return data
}

function defaultParseResponseCreate(data: any) {
  return data.data
}

function defaultParseResponseUpdate(data: any) {
  return data.data
}

function defaultParseResponseUpdateMany(data: any) {
  const ids = data.data.data.filter(item => item).map((item: any) => item.id)

  return {
    data: ids,
  }
}

function defaultParseResponseGetMany(data: any) {
  return data
}

function defaultParseResponseDelete(data: any) {
  return data.data
}

function defaultParseResponseDeleteMany(data: any) {
  const ids = data.data.data.map((item: any) => item.id)
  return {
    data: ids,
  }
}

function paginationFromParams(params: any): Pagination {
  return {
    page: params.pagination.page,
    pageSize: params.pagination.perPage,
  }
}

function sortFromParams(params: any): Sort {
  return {
    field: params.sort.field,
    direction: params.sort.order,
  }
}

function defaultMakeInput(params: any) {
  const { createdAt, updatedAt, ...other } = params
  return other
}

function defaultMakeVariablesCreate(input: any) {
  return { input }
}

function defaultMakeVariablesUpdate(id: string, input: any) {
  return { id, input }
}

function defaultMakeDiff(previousData: any, data: any) {
  return diff(previousData, data)
}

async function getExportPagination(
  graphqlClient: GraphQLClient,
  query: QueryBuilderUnit['query'],
  params: any,
  isExport: boolean,
) {
  const filterBy = params.filter
  const sort = sortFromParams(params)
  const orderBy = defaultOrderByMapper(sort)

  if (isExport) {
    const res = await graphqlClient.query(query, {
      orderBy,
      filterBy,
      pagination: { page: 1, pageSize: 1 },
    })

    return {
      pagination: {
        page: 1,
        pageSize: res.data.data.total,
      },
      orderBy,
      filterBy,
    }
  } else {
    const pagination = paginationFromParams(params)

    return {
      pagination,
      orderBy,
      filterBy,
    }
  }
}

async function executeGetList(
  graphqlClient: GraphQLClient,
  { parseRecord }: QueryBuilder,
  { query, parseResponse }: QueryBuilderUnit,
  params: any,
) {
  const isExport = params.pagination.perPage > 500
  const variables = await getExportPagination(
    graphqlClient,
    query,
    params,
    isExport,
  )

  const finalParseResponse = parseResponse || defaultParseResponseGetList
  const response = await graphqlClient.query(query, variables)
  const result = finalParseResponse(response.data)
  if (!parseRecord) {
    return result
  }

  return {
    ...result,
    data: result.data.map(parseRecord),
  }
}

async function executeGetOne(
  graphqlClient: GraphQLClient,
  { parseRecord }: QueryBuilder,
  { query, parseResponse }: QueryBuilderUnit,
  params: any,
) {
  const finalParseResponse = parseResponse || defaultParseResponseGetOne

  const variables = {
    id: params.id,
  }

  const response = await graphqlClient.query(query, variables)
  const result = finalParseResponse(response.data)
  if (!parseRecord) {
    return result
  }

  return {
    ...result,
    data: parseRecord(result.data),
  }
}

async function executeGetMany(
  graphqlClient: GraphQLClient,
  { parseRecord }: QueryBuilder,
  { query, parseResponse }: QueryBuilderUnit,
  params: any,
) {
  const finalParseResponse = parseResponse || defaultParseResponseGetMany

  const variables = {
    ids: params.ids,
  }
  const response = await graphqlClient.query(query, variables)
  const result = finalParseResponse(response.data)
  if (!parseRecord) {
    return result
  }

  return {
    ...result,
    data: result.data.map(parseRecord),
  }
}

async function executeGetManyReference(
  graphqlClient: GraphQLClient,
  queryBuilder: QueryBuilder,
  queryBuilderGetManyReference: QueryBuilderUnit,
  params: any,
) {
  const { id, target, filter, ...otherParams } = params

  return executeGetList(
    graphqlClient,
    queryBuilder,
    queryBuilderGetManyReference,
    {
      ...otherParams,
      filter: { ...filter, [target]: id },
    },
  )
}

async function executeCreate(
  graphqlClient: GraphQLClient,
  { parseRecord, prepareInput }: QueryBuilder,
  {
    query,
    input: makeInput,
    variables: makeVariables,
    parseResponse,
  }: QueryBuilderUnit,
  params: any,
) {
  const finalMakeInput = makeInput || defaultMakeInput
  const finalMakeVariables = makeVariables || defaultMakeVariablesCreate
  const finalParseResponse = parseResponse || defaultParseResponseCreate

  let input = finalMakeInput(params.data)
  if (prepareInput) {
    input = prepareInput(input)
  }

  const response = await graphqlClient.mutate(query, finalMakeVariables(input))
  const result = finalParseResponse(response.data)
  if (!parseRecord) {
    return result
  }

  return {
    ...result,
    data: parseRecord(result.data),
  }
}

async function executeUpdate(
  graphqlClient: GraphQLClient,
  { parseRecord, prepareInput }: QueryBuilder,
  {
    query,
    diff: makeDiff,
    variables: makeVariables,
    parseResponse,
  }: QueryBuilderUnit,
  params: any,
) {
  const finalMakeDiff = makeDiff || defaultMakeDiff
  const finalMakeVariables = makeVariables || defaultMakeVariablesUpdate
  const finalParseResponse = parseResponse || defaultParseResponseUpdate

  const { id } = params
  let input = finalMakeDiff(
    stripTypename(params.previousData),
    stripTypename(params.data),
  )
  if (prepareInput) {
    input = prepareInput(input)
  }

  const response = await graphqlClient.mutate(
    query,
    finalMakeVariables(id, input),
  )
  const result = finalParseResponse(response.data)
  if (!parseRecord) {
    return result
  }

  return {
    ...result,
    data: parseRecord(result.data),
  }
}

async function executeUpdateMany(
  graphqlClient: GraphQLClient,
  { parseRecord }: QueryBuilder,
  { query, parseResponse }: QueryBuilderUnit,
  params: any,
) {
  const finalParseResponse = parseResponse || defaultParseResponseUpdateMany

  const { ids, data: input } = params

  const response = await graphqlClient.mutate(query, { ids, input })
  return finalParseResponse(response.data)
}

async function executeDelete(
  graphqlClient: GraphQLClient,
  {}: QueryBuilder,
  { query, parseResponse }: QueryBuilderUnit,
  params: any,
) {
  const finalParseResponse = parseResponse || defaultParseResponseDelete

  const variables = {
    id: params.id,
  }
  const result = await graphqlClient.mutate(query, variables)
  return finalParseResponse(result.data)
}

async function executeDeleteMany(
  graphqlClient: GraphQLClient,
  {}: QueryBuilder,
  { query, parseResponse }: QueryBuilderUnit,
  params: any,
) {
  const finalParseResponse = parseResponse || defaultParseResponseDeleteMany

  const variables = {
    ids: params.ids,
  }
  const result = await graphqlClient.mutate(query, variables)
  return finalParseResponse(result.data)
}

export interface GraphQLClient {
  query: (query: any, variables: any) => Promise<any>
  mutate: (mutation: any, variables: any) => Promise<any>
}

export function createDataProvider(
  graphqlClient: GraphQLClient,
  queryBuilders: Record<string, QueryBuilder>,
) {
  return {
    getList: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.getList) {
        throw new Error(`Cannot find getList "${resource}"`)
      }

      // 게시글 조회시엔 백엔드 성능 이슈로 인해 field: title, q -> word 필드로 변경하여 API를 요청합니다
      const isPostFilter = resource === 'posts' && params.filter.word

      const listParams = isPostFilter
        ? {
            ...params,
            filter: {
              ...params.filter,
              field: 'title',
            },
          }
        : params

      return executeGetList(
        graphqlClient,
        queryBuilder,
        queryBuilder.getList,
        listParams,
      )
    },
    getOne: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.getOne) {
        throw new Error(`Cannot find getOne "${resource}"`)
      }
      return executeGetOne(
        graphqlClient,
        queryBuilder,
        queryBuilder.getOne,
        params,
      )
    },
    getMany: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.getMany) {
        throw new Error(`Cannot find getMany "${resource}"`)
      }
      return executeGetMany(
        graphqlClient,
        queryBuilder,
        queryBuilder.getMany,
        params,
      )
    },
    getManyReference: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.getManyReference) {
        throw new Error(`Cannot find getManyReference "${resource}"`)
      }
      return executeGetManyReference(
        graphqlClient,
        queryBuilder,
        queryBuilder.getManyReference,
        params,
      )
    },
    create: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.create) {
        throw new Error(`Cannot find create "${resource}"`)
      }
      return executeCreate(
        graphqlClient,
        queryBuilder,
        queryBuilder.create,
        params,
      )
    },
    update: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.update) {
        throw new Error(`Cannot find update "${resource}"`)
      }
      return executeUpdate(
        graphqlClient,
        queryBuilder,
        queryBuilder.update,
        params,
      )
    },
    updateMany: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.updateMany) {
        throw new Error(`Cannot find update "${resource}"`)
      }
      return executeUpdateMany(
        graphqlClient,
        queryBuilder,
        queryBuilder.updateMany,
        params,
      )
    },
    delete: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.delete) {
        throw new Error(`Cannot find deleteMany "${resource}"`)
      }
      return executeDelete(
        graphqlClient,
        queryBuilder,
        queryBuilder.delete,
        params,
      )
    },
    deleteMany: async (resource: string, params: any) => {
      const queryBuilder = queryBuilders[resource]
      if (!queryBuilder || !queryBuilder.deleteMany) {
        throw new Error(`Cannot find deleteMany "${resource}"`)
      }
      return executeDeleteMany(
        graphqlClient,
        queryBuilder,
        queryBuilder.deleteMany,
        params,
      )
    },
  }
}
